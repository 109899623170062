import type {
  Command,
  Language,
  SessionOutput,
} from '@datacamp/multiplexer-client';
import isDeepEqual from 'lodash/isEqual';
import zip from 'lodash/zip';
import raven from 'raven-js';

import type { State } from '../interfaces/State';

import type { CodeExecutionBackend } from './codeExecutionBackend';
import { createMuxBackend } from './muxBackend';
import prometheusClient from './prometheusClient';
import { createPyodideBackend } from './pyodideBackend';

let _client: ReturnType<typeof prometheusClient> | undefined;

const client = () => {
  if (_client == null) {
    _client = prometheusClient();
  }
  return _client;
};

const hasOutputDifferentFormattingAcrossDifferentVersions = (
  pyodideOutput: SessionOutput,
  muxOutput: SessionOutput,
): boolean => {
  const commonOutputs = [
    'Signature: ', // Ignore difference outputting function signatures
    'Init signature: ', // Ignore difference outputting function signatures
    'Docstring:', // Ignore difference outputting docstring
    'Help on ', // Ignore difference outputting help information
    '[9.5, 10.75, 11.25, 18.0, 20.0]\nHelp on ', // Ignore specific exercise that includes the above not at the start
    '<function ', // Ignore difference outputting functions
    "<module '", // Ignore difference outputting modules
    '<built-in method ', // Ignore difference printing object's built-in methods
    'poolhouse\n<built-in method ', // Ignore specific exercise that includes the above not at the start
    'Type help', // Ignore difference outputting the help function
    'Average: 73.6896551724138', // Ignore specific exercise where different versions may show different decimal places
  ];
  return (
    typeof pyodideOutput.payload === 'string' &&
    typeof muxOutput.payload === 'string' &&
    commonOutputs.some(
      (text) =>
        pyodideOutput.payload.startsWith(text) &&
        muxOutput.payload.startsWith(text),
    )
  );
};

const measureTimeToRunOrSubmitCode = ({
  environment,
  time,
}: {
  environment: 'mux' | 'wasm';
  time: number;
}) => {
  client().observeDistribution(
    'campus__pyodide_experiment_time_to_run_or_submit',
    { environment },
    time,
  );
};

const measureTimeUntilSessionReady = ({
  environment,
  exercise,
  time,
}: {
  environment: 'mux' | 'wasm';
  exercise: string;
  time: number;
}) => {
  client().observeDistribution(
    'campus__pyodide_experiment_time_until_session_ready',
    { environment, exercise },
    time,
  );
};

const countPyodideSessionNotReadyWhenRunningCode = () => {
  client().increment(
    'campus__pyodide_session_not_ready_when_running_code',
    {},
    1,
  );
};

const countNumberOfOutputsMismatch = () => {
  client().increment(
    'campus__pyodide_experiment_number_of_outputs_mismatch',
    {},
    1,
  );
};

const countOutputTypeMismatch = () => {
  client().increment('campus__pyodide_experiment_output_type_mismatch', {}, 1);
};

const countOutputValueMismatch = () => {
  client().increment('campus__pyodide_experiment_output_value_mismatch', {}, 1);
};

export type DualCodeExecutionBackend = CodeExecutionBackend & {
  options: {
    language: Language;
    userInfo: unknown;
  };
};

// This is a code execution backend combined of the mux + pyodide, but we only show the mux results to the user.
// We send the same commands to the pyodide backend just to measure user impact.
export const dualCodeExecutionBackend = ({
  language,
  state,
  userInfo,
}: {
  language: Language;
  state: State;
  userInfo: unknown;
}): DualCodeExecutionBackend => {
  const muxSession = createMuxBackend({ language, state, userInfo });
  const pyodideBackend = createPyodideBackend({ userInfo });

  const sessionCreatedAt = Date.now();

  let pyodideReady = false;
  let muxReady = false;
  let ignoredPyodide = false;

  let pyodideInputStartedAt: number | null = null;
  let muxInputStartedAt: number | null = null;

  let pyodideOutputHistory: SessionOutput[] = [];
  let muxOutputHistory: SessionOutput[] = [];
  const fullPyodideOutputHistory: Array<SessionOutput | { timestamp: string }> =
    [];
  const fullMuxOutputHistory: Array<SessionOutput | { timestamp: string }> = [];
  const fullInputHistory: Array<Command | { timestamp: string }> = [];

  const compareOutputs = () => {
    if (pyodideOutputHistory.length === 0 || muxOutputHistory.length === 0) {
      return;
    }

    if (pyodideOutputHistory.length !== muxOutputHistory.length) {
      if (
        muxOutputHistory.length === pyodideOutputHistory.length + 1 &&
        muxOutputHistory[0].type === 'output' &&
        muxOutputHistory[0].payload
          .split('\n')
          .some((line) =>
            line.startsWith(
              'ERROR! Session/line number was not unique in database',
            ),
          )
      ) {
        raven.captureMessage('Session error in mux', {
          level: 'warning',
          extra: {
            pyodideOutputHistory,
            muxOutputHistory,
          },
        });
        muxOutputHistory.shift();
      } else {
        raven.captureMessage('Number of outputs mismatch', {
          level: 'warning',
          extra: {
            pyodideOutputHistory,
            muxOutputHistory,
            fullPyodideOutputHistory,
            fullMuxOutputHistory,
            fullInputHistory,
          },
        });
        countNumberOfOutputsMismatch();
        return;
      }
    }

    const outputs = zip(pyodideOutputHistory, muxOutputHistory);

    // eslint-disable-next-line no-restricted-syntax
    for (const [pyodideOutput, muxOutput] of outputs) {
      if (pyodideOutput == null || muxOutput == null) {
        // should be unreachable
        break;
      }

      if (pyodideOutput.type !== muxOutput.type) {
        raven.captureMessage('Output type mismatch', {
          level: 'warning',
          extra: {
            pyodideOutput,
            muxOutput,
            fullPyodideOutputHistory,
            fullMuxOutputHistory,
            fullInputHistory,
          },
        });
        countOutputTypeMismatch();
        continue; // eslint-disable-line no-continue
      }

      switch (pyodideOutput.type) {
        case 'error': {
          // We don't compare error messages
          break;
        }
        case 'sct': {
          if (!pyodideOutput.payload.correct && !muxOutput.payload.correct) {
            // We don't compare SCT errors - if both are incorrect it's ok.
            // This is because error messages are different between pyodide <> mux because of the different python
            // versions.
            // It somewhat decreases the value of the experiment, but it's better than having a lot of false positives.
            // As an alternative, we can check for the content "syntax error" and only ignore those.
            break;
          }
          if (!isDeepEqual(pyodideOutput, muxOutput)) {
            raven.captureMessage('Output value mismatch', {
              level: 'warning',
              extra: {
                pyodideOutput,
                muxOutput,
                fullPyodideOutputHistory,
                fullMuxOutputHistory,
              },
            });
            countOutputValueMismatch();
          }
          break;
        }
        default: {
          // Ignore some expected differences between the different Python versions, to reduce false positives.
          if (
            hasOutputDifferentFormattingAcrossDifferentVersions(
              pyodideOutput,
              muxOutput,
            )
          ) {
            break;
          }

          if (!isDeepEqual(pyodideOutput, muxOutput)) {
            raven.captureMessage('Output value mismatch', {
              level: 'warning',
              extra: {
                pyodideOutput,
                muxOutput,
                fullPyodideOutputHistory,
                fullMuxOutputHistory,
                fullInputHistory,
              },
            });
            countOutputValueMismatch();
          }
          break;
        }
      }
    }

    pyodideOutputHistory = [];
    muxOutputHistory = [];
  };

  const addOutputToPyodideHistory = (
    output: SessionOutput | SessionOutput[],
  ) => {
    const outputs = Array.isArray(output) ? output : [output];
    pyodideOutputHistory.push(...outputs);
    fullPyodideOutputHistory.push(
      ...outputs.map((out) => ({
        ...out,
        timestamp: new Date().toISOString(),
      })),
    );

    compareOutputs();
  };

  const addOutputToMuxHistory = (output: SessionOutput | SessionOutput[]) => {
    const outputs = Array.isArray(output) ? output : [output];
    muxOutputHistory.push(...outputs);
    fullMuxOutputHistory.push(
      ...outputs.map((out) => ({
        ...out,
        timestamp: new Date().toISOString(),
      })),
    );

    compareOutputs();
  };

  muxSession.output$.subscribe((output) => {
    if (!ignoredPyodide) {
      // Only add to history outputs that will be compared. If pyodide
      // was ignored, this output won't be compared.
      addOutputToMuxHistory(output);
    }

    if (muxInputStartedAt == null) {
      return;
    }

    const timeToExecuteCode = Date.now() - muxInputStartedAt;

    measureTimeToRunOrSubmitCode({
      environment: 'mux',
      time: timeToExecuteCode,
    });

    muxInputStartedAt = null;
  });

  pyodideBackend.output$.subscribe((output) => {
    addOutputToPyodideHistory(output);

    if (pyodideInputStartedAt == null) {
      return;
    }

    const timeToExecuteCode = Date.now() - pyodideInputStartedAt;

    measureTimeToRunOrSubmitCode({
      environment: 'wasm',
      time: timeToExecuteCode,
    });

    pyodideInputStartedAt = null;
  });

  muxSession.subscribe(({ status }) => {
    if (status === 'ready' && !muxReady) {
      muxReady = true;
      const timeUntilReady = Date.now() - sessionCreatedAt;
      measureTimeUntilSessionReady({
        environment: 'mux',
        exercise: window.location.href.replace(/^.+\//g, ''),
        time: timeUntilReady,
      });
    }
  });

  pyodideBackend.subscribe(({ status }) => {
    if (status === 'ready' && !pyodideReady) {
      pyodideReady = true;
      const timeUntilReady = Date.now() - sessionCreatedAt;
      measureTimeUntilSessionReady({
        environment: 'wasm',
        exercise: window.location.href.replace(/^.+\//g, ''),
        time: timeUntilReady,
      });
    }
  });

  return {
    client: {
      off(event) {
        muxSession.client.off(event);
      },
      on(event, callback) {
        muxSession.client.on(event, callback);
      },
    },
    input(command) {
      muxInputStartedAt = Date.now();
      muxSession.input(command);

      if (pyodideReady) {
        ignoredPyodide = false;
        pyodideInputStartedAt = Date.now();
        pyodideBackend.input(command);
        fullInputHistory.push({
          ...command,
          timestamp: new Date().toISOString(),
        });
      } else {
        ignoredPyodide = true;
        countPyodideSessionNotReadyWhenRunningCode();
      }
    },
    options: { language, userInfo },
    // We only show the mux results to the user
    output$: muxSession.output$,
    start(options, initCommand) {
      muxSession.start(options, initCommand);
      pyodideBackend.start(options, initCommand);
    },
    stop() {
      pyodideBackend.stop();
      return muxSession.stop();
    },
    stopPolling() {
      pyodideBackend.stopPolling();
      return muxSession.stopPolling();
    },
    subscribe(subscriber) {
      // We only show mux readiness status to the user
      return muxSession.subscribe(subscriber);
    },
    subscribeToOutputs(predicate, listener) {
      // We only show mux outputs to the user
      return muxSession.subscribeToOutputs(predicate, listener);
    },
  };
};
