import type { Language } from '@datacamp/multiplexer-client';
import mux from '@datacamp/multiplexer-client';

import type { State } from '../interfaces/State';
import * as selectors from '../redux/selectors';

import type { CodeExecutionBackend } from './codeExecutionBackend';

export const createMuxBackend = ({
  language,
  state,
  userInfo,
}: {
  language: Language;
  state: State;
  userInfo: unknown;
}): CodeExecutionBackend => {
  return new mux.AsyncSession(userInfo, {
    multiplexerUrl: selectors.selectMultiplexerUrl(state),
    language,
    flattenOutputs: false,
    retryOptions: { delay: 1000, nbOfRetry: 10 },
  });
};
